const ENV = {
  // baseDmsUrl: '/',
  // baseApiEndpoint: 'http://localhost:2828/feapi/',
  // basePortalUrl: 'http://localhost:2828/',

  // baseDmsUrl: "/",
  // baseApiEndpoint: "https://localhost:44334/feapi/",
  // basePortalUrl: "https://localhost:44334/",
  // spireUrl: "http://localhost:3000/",

  baseDmsUrl: "https://qlpbgdpl.thi360.com/",
  basePortalUrl: "https://pbgdpl.thi360.com/",
  baseApiEndpoint: "https://pbgdpl.thi360.com/feapi/",
  spireUrl: "https://pbgdplv2.thi360.com/",
 
  // baseDmsUrl: 'https://qlpbgdpl.thanhhoa.gov.vn/',
  // basePortalUrl: 'https://pbgdpl.thanhhoa.gov.vn/',
  // baseApiEndpoint: 'https://qlpbgdpl.thanhhoa.gov.vn/api/',
  baseFileImageUrl: "https://pbgdpl.thanhhoa.gov.vn/",
  clientId: "099153c2625149bc8ecb3e85e03f0022",
};

export default ENV;
